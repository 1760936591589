import { useEffect } from "react";
import "./app.css";
import "./custom.css";

export default () => {
    const data = [{ id: "84e3526d-a1b5-432a-a765-7e3097660d9e", name: "Old Mates Burgers", available: true }];

    const onOrder = (link) => {
      window.location.href = `https://order.tabin.co.nz/restaurant/${link}`;
    };

    return (
        <>
        <div className="restaurant-list">
          <img
            src={`https://d1hfsnuz4i23pd.cloudfront.net/public/2024-02-21_04:10:45.458-old mates burgers logo.webp`}
            className="restaurant-list-logo"
            alt="logo"
          />
          <div className="h4 text-center mb-6 description">
          At Old Mates Burgers we serve seriously delicious burgers made from quality ingredients. Click Order Here to start!
          </div>
          {data &&
            data.map((restaurant, index) => (
              <div key={restaurant.id}>
                {index != 0 && <div className="separator-4"></div>}
                <div className="restaurant-list-item">
                  <div className="restaurant-name">{restaurant.name}</div>
                  <button
                    className={`button ${restaurant.available ? '' : 'disabled'}`}
                    onClick={() => {
                        restaurant.available && 
                      onOrder(restaurant.id);
                    }}
                  >
                    {restaurant.available ? 'Order Here' : 'Unavailable'}
                  </button>
                </div>
              </div>
            ))}
        </div>
        </>
    );
};
